import { useMediaQuery } from '@kaliber/use-media-query'
import { Icon } from '/features/buildingBlocks/Icon'
import { dayjs } from '/machinery/dayjs'

import iconClock from '/images/icons/boxed-clock.raw.svg'
import iconArrow from '/images/icons/arrow-right.raw.svg'
import iconDate from '/images/icons/boxed-calendar.raw.svg'
import iconLocation from '/images/icons/boxed-location.raw.svg'

import { viewportMd } from '/cssGlobal/media.css'
import styles from './EventCard.css'

export function EventCardDetailed({ title, description, endDate, startDate, time, location, externalLink }) {
  return (
    <EventCardBase
      withButtonText
      layoutClassName={styles.detailedGrid}
      {... { title, description, startDate, endDate, time, location, externalLink }}
    />
  )
}

export function EventCardCompact({ title, startDate, endDate, time, location, externalLink }) {
  return (
    <EventCardBase
      layoutClassName={styles.compactGrid}
      {... { title, startDate, endDate, time, location, externalLink }}
    />
  )
}

function EventCardBase({
  title,
  startDate,
  time,
  location,
  externalLink,
  description = undefined,
  withButtonText = undefined,
  layoutClassName = undefined
}) {
  const isViewportMd = useMediaQuery(viewportMd)
  const isValidDate = dayjs(time).isValid()
  const day = dayjs(startDate).format('DD')
  const month = dayjs(startDate).format('MMMM')

  return (
    <div className={cx(styles.componentBase, layoutClassName)}>
      {isViewportMd && (
        <Calendar layoutClassName={styles.calendarLayout} {... { day, month }} />
      )}
      {externalLink
        ? <LinkTitle layoutClassName={styles.titleLayout} link={externalLink} {... { title }} />
        : <TextTitle layoutClassName={styles.titleLayout} {... { title }} />
      }
      {!isViewportMd && (
        <Chip icon={iconDate} layoutClassName={styles.mobileDateLayout}>
          {day} {month}
        </Chip>
      )}
      <Chip icon={iconClock} layoutClassName={styles.dateLayout}>
        <time className={styles.date} {...(isValidDate && { dateTime: time })}>
          {time}
        </time>
      </Chip>
      <Chip icon={iconLocation} layoutClassName={styles.locationLayout}>
        {location}
      </Chip>
      {description && (
        <Description layoutClassName={styles.descriptionLayout} {... { description }} />
      )}
      {externalLink && (
        <PseudoButton
          link={externalLink}
          layoutClassName={styles.buttonLayout}
          {... { withButtonText }}
        />
      )}
    </div>
  )
}

function LinkTitle({ link, title, layoutClassName = undefined }) {
  return (
    <a
      className={cx(styles.componentLinkTitle, layoutClassName)}
      href={link?.href}
      data-x='link-to-event'
      target='_blank'
      rel='noreferrer'
    >
      {title}
    </a>
  )
}

function TextTitle({ title, layoutClassName = undefined }) {
  return (
    <p className={cx(styles.componentTextTitle, layoutClassName)}>
      {title}
    </p>
  )
}

function Description({ description, layoutClassName = undefined }) {
  return (
    <p className={cx(styles.componentDescription, layoutClassName)}>
      {description}
    </p>
  )
}

function Calendar({ day, month, layoutClassName = undefined }) {
  return (
    <svg
      viewBox="0 0 93 90"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(styles.componentCalendar, layoutClassName)}
    >
      <path
        className={styles.svgCalendarPath}
        d="M28 0v10h37V0h3v10h18a7 7 0 0 1 7 7v66a7 7 0 0 1-7 7H7a7 7 0 0 1-7-7V17a7 7 0 0 1 7-7h18V0h3Zm62 31H3v52a4 4 0 0 0 4 4h79a4 4 0 0 0 4-4V31ZM25 13H7a4 4 0 0 0-4 4v11h87V17a4 4 0 0 0-4-4H68v9h-3v-9H28v9h-3v-9Z"
      />
      <foreignObject x="8" y="31" width="77px" height="55px" className={styles.svgForeignObject}>
        <div className={styles.calendarInner}>
          <p className={styles.calendarDay}>{day}</p>
          <p className={styles.calendarMonth}>{month}</p>
        </div>
      </foreignObject>
    </svg>
  )
}

function Chip({ children, icon, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentChip, layoutClassName)}>
      <Icon layoutClassName={styles.iconLayout} {... { icon }} />
      <div>{children}</div>
    </div>
  )
}

function PseudoButton({ link, withButtonText = undefined, layoutClassName = undefined }) {
  return (
    <div role="presentation" className={cx(styles.componentPseudoButton, layoutClassName)}>
      <span className={styles.buttonIconContainer}>
        <Icon layoutClassName={styles.icon} icon={iconArrow} />
      </span>
      {withButtonText && (
        <span>{link?.title}</span>
      )}
    </div>
  )
}
