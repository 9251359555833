import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { EventCardDetailed } from '/features/events/EventCard'
import { useTrail, animated } from '@react-spring/web'

import styles from './StaggeredEventCards.css'

export function StaggeredEventCards({ events }) {
  const { ref: elementRef, wasInViewport } = useWasInViewport()
  const trail = useTrail(events?.length ?? 0, {
    delay: 200,
    to: {
      opacity: wasInViewport ? 1 : 0,
      y: wasInViewport ? '0' : '50%',
      scale: wasInViewport ? 1 : 0.9
    },
    config: { tension: 150, friction: 35 },
  })

  return (
    <div ref={elementRef} className={styles.component}>
      {events.map((x, i) => (
        <animated.div key={x._id} style={trail[i]}>
          <EventCardDetailed
            title={x.title}
            time={x.time}
            location={x.location}
            description={x.description}
            startDate={x.startDate}
            endDate={x.endDate}
            externalLink={x.externalLink}
          />
        </animated.div>
      ))}
    </div>
  )
}
